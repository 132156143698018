import * as React from 'react'
import { useState, useEffect } from 'react'
import { Router } from '@reach/router'
import useUrlState from '../hooks/useUrlState'
import { BlogPostSectionRenderer } from '../section-renderer/blog-post'
import { RepairBlogSectionRenderer } from '../section-renderer/repair-blog'
import { RepairBlogCategorySectionRenderer } from '../section-renderer/repair-blog-category'
import CategoryRenderer from '../section-renderer/category'
import CmsPageSectionRenderer from '../section-renderer/page'
import BlogPostRelData from '../section-renderer/blog-post-additional-data'
import PropTypes from 'prop-types'
import { algoliaSearch, mergeFacets } from '../helpers/AlgoliaClient'

const RenderData = ({ type, page, postRelData  }) => {
  switch (type) {
    case 'blog-post': {
      return (
        <BlogPostSectionRenderer
          breadcrumbs={[{ url: '/blog/', name: 'Blog' }]}
          products={postRelData.products}
          recommended={postRelData.recommended}
          page={page.page}
        />
      )
    }
    case 'repair-blog-post': {
      return (
        <RepairBlogSectionRenderer
          data={{ page: page.page, models: page.models, services: page.services }}
        />
      )
    }
    case 'repair-blog-category': {
      return <RepairBlogCategorySectionRenderer 
        data={{ page: page.page, models: page.models, services: page.services }}
      />
    }
    case 'page': {
      return <CmsPageSectionRenderer data={page} />
    }
    case 'category': {
      return (
        <CategoryRenderer
          data={{ category: page.page, facets: page.facets ||  {}, items: page.items || { pageInfo: { currentPage: 1 }, nodes: [] } }}
          location={location}
          pageContext={{}}
        />
      )
    }
    default:
      return <div className="whitespace-pre">{JSON.stringify(page, null, 2)}</div>
  }
}
RenderData.propTypes = {
  type: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  postRelData: PropTypes.object.isRequired,
  firstSection: PropTypes.object,
  otherSections: PropTypes.array,
}
const Details = () => {
  const [slug] = useUrlState('slug')
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const _page = {
    meta_title: 'Preview',
    slug: slug || 'preview',
    meta_description: 'Preview',
    title: 'Preview',
  }
  const [page, setPage] = useState(_page)

  const apiUrl = (process.env.GATSBY_API_URL || '/api')
  useEffect(() => {
    if (!slug) return
    setIsLoading(true)
    fetch(`${apiUrl}/get-page?slug=${encodeURIComponent(slug)}`, { method: 'POST' })
      .then(res => res.json())
      .then(
        data => {
          if(data.contentType === 'category') {
            // fetch first page of items
            const controller = new AbortController()
            algoliaSearch(
              '',
              0,
              {},
              'products',
              controller,
              { strapi_id: data.page.id },
              false,
              false,
              [-1, -1]
            ).then(searchResponse => {
              if (controller.signal.aborted) return
              data.facets = mergeFacets({}, {}, searchResponse)
              data.items = {
                nodes: searchResponse.hits,
                pageInfo: {
                  currentPage: 1,
                  perPage: searchResponse.hitsPerPage,
                  totalCount: searchResponse.nbHits,
                  itemCount: searchResponse.hits.length,
                  searched: false,
                },
              }
              console.log('data', data)
              setPage(data)
              setIsError(false)
              setIsLoading(false)
            })
          } else {
            console.log('data', data)
            setPage(data)
            setIsError(false)
            setIsLoading(false)
          }
        },
        err => {
          console.log('err', err)
          setIsError(true)
          setIsLoading(false)
        }
      )
  }, [slug])

  const postRelData = BlogPostRelData()

  if (!isLoading) {
    console.log('pageData', page)
  }

  return (
    <>
      {isError && <div className="container mx-auto">Error - Can't load page!!!</div>}
      {isLoading ? (
        <div className={'container mx-auto px-4'}>Loading {page.slug}...</div>
      ) : (
        <section>
          <RenderData
            postRelData={postRelData}
            type={page.contentType}
            page={page}
          />
        </section>
      )}
    </>
  )
}

const PreviewPage = () => {
  return (
    <Router basepath="/preview">
      <Details path="/:slug" />
      <Details path="/" />
    </Router>
  )
}

export default PreviewPage
